import React from "react";

import Header from "../Header";
import Footer from "../Footer";

import styles from "./layout.module.scss";

export default function Layout({ children }) {
    return (
        <div className={styles.layout}>
            <Header />
            <main>
                <div className={styles.container}>{children}</div>
            </main>
            <Footer />
        </div>
    );
}
