import React from "react";
import { Link } from "gatsby";

import styles from "./header.module.scss";

const Header = () => (
    <div className={styles.header}>
        <div className={styles.content}>
            <div>
                <Link className={`${styles.navLink} ${styles.home}`} to="/">
                    Prikshit
                </Link>
            </div>
            <div>
                <Link className={styles.navLink} to="/blog">
                    Blog
                </Link>
                <Link className={styles.navLink} to="/projects">
                    Projects
                </Link>
            </div>
        </div>
    </div>
);

export default Header;
