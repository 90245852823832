import React from "react";

import styles from "./footer.module.scss";

const Footer = () => (
    <div className={styles.footer}>
        <p>© {new Date().getFullYear()} Prikshit Tekta</p>
    </div>
);

export default Footer;
